import React, { useState } from 'react';
import { useConfiguredRollbar } from '@vp/digital-rollbar-lib';
import Markdown from 'markdown-to-jsx';
import {
  BoundedContent,
  Box,
  Button,
  Column,
  FluidImage,
  GridContainer,
  Row,
  Hidden,
  Typography,
} from '@vp/swan';
import getResponsiveCloudinaryImage from '../../helpers/cloudinaryImageResizerHelper';
import { SEOContainer } from '@vp/digital-seo-lib';
import { FaqContainer } from '@vp/digital-faq-lib';
import Redirect from '../common/Redirect';

import './GoogleWorkspacePdpAPage.scss';

const GoogleWorkspacePdpA = ({ pageContext }: any) => {
  useConfiguredRollbar();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [redirectPath, setRedirectPath] = useState(null);
  const {
    hero,
    whatDoINeedToGetBusinessEmail,
    whatIsABusinessEmailText,
    whyYouNeedBusinessEmail,
    faqs,
    seo,
  } = pageContext.pageData;

  return (
    <Redirect redirectPath={redirectPath} isRedirecting={isRedirecting}>
      <>
        {seo && <SEOContainer content={seo} />}

        {/* Google Workspace PDP hero */}
        {hero && (
          <Box className="google-workspace-pdp-a__hero__container">
            <BoundedContent>
              <GridContainer>
                <Row>
                  <Column
                    offset={0}
                    span={12}
                    spanMd={6}
                    offsetLg={1}
                    spanLg={5}
                    offsetXl={1}
                    spanXl={5}
                  >
                    <Box>
                      <FluidImage
                        className="google-workspace-pdp-a__hero__logo"
                        src={hero.backgroundImage.media.secureUrl}
                        alt={hero.backgroundImage.media.fileName}
                      />
                    </Box>
                    <Typography component="h2" mb={0}>
                      {hero.title}
                    </Typography>
                    <Typography mt={5} component="p">
                      {hero.descriptionLong}
                    </Typography>
                    <Box mt={5}>
                      <>
                        <Button skin="secondary">{hero.ctaName}</Button>
                      </>
                    </Box>
                  </Column>
                  <Column span={12} spanMd={6} spanLg={6} spanXl={6}>
                    <FluidImage
                      src={hero.mediaList[1].media.secureUrl}
                      alt={hero.mediaList[1].altText}
                    />
                  </Column>
                </Row>
              </GridContainer>
            </BoundedContent>
          </Box>
        )}
        {/* Google Workspace PDPA  What is a business email address? */}
        {whatIsABusinessEmailText && (
          <Box className="google-workspace-pdp-a__what-is-business-email__container">
            <BoundedContent>
              <GridContainer>
                <Row>
                  <Column
                    offset={0}
                    span={12}
                    spanMd={7}
                    offsetLg={1}
                    spanLg={6}
                    offsetXl={1}
                    spanXl={6}
                  >
                    <Typography component="h2" fontSize="x2" fontWeight="bold">
                      {whatIsABusinessEmailText.title}
                    </Typography>
                    <Markdown options={{ forceBlock: true }}>
                      {whatIsABusinessEmailText.description}
                    </Markdown>
                  </Column>
                  <Column span={12} spanMd={5} spanLg={5} spanXl={5}>
                    <Hidden sm xs>
                      <FluidImage
                        srcSet={getResponsiveCloudinaryImage(
                          whatIsABusinessEmailText.mediaUrl
                        )}
                        src={whatIsABusinessEmailText.mediaUrl}
                        alt={whatIsABusinessEmailText.altText}
                      />
                    </Hidden>
                  </Column>
                </Row>
              </GridContainer>
            </BoundedContent>
          </Box>
        )}

        {/* What do i need to be able to get a business email address */}
        {whatDoINeedToGetBusinessEmail && (
          <Box
            className="google-workspace-pdp-a__sub-hero__container"
            style={{
              backgroundColor: whatDoINeedToGetBusinessEmail.backgroundColor,
            }}
          >
            <BoundedContent>
              <GridContainer>
                <Row>
                  <Column offset={0} span={12}>
                    <Typography
                      component="h1"
                      mb={0}
                      className="google-workspace-pdp-a__sub-hero__title"
                    >
                      {whatDoINeedToGetBusinessEmail.title}
                    </Typography>
                    <Typography mt={7} mb={8} component="p">
                      {whatDoINeedToGetBusinessEmail.descriptionLong}
                    </Typography>
                    <Button className="google-workspace-pdp-a__sub-hero__get-started-cta">
                      {whatDoINeedToGetBusinessEmail.ctaName}
                    </Button>
                  </Column>
                </Row>
              </GridContainer>
            </BoundedContent>
          </Box>
        )}

        {/* Wix PDP-A faq */}
        {faqs && (
          <article className="google-workspace-pdp-a__faq__container">
            <BoundedContent>
              <GridContainer>
                <Row>
                  <Column
                    span={12}
                    spanLg={10}
                    offsetLg={1}
                    spanXl={10}
                    offsetXl={1}
                  >
                    <FaqContainer content={faqs} titleSize={5} />
                  </Column>
                </Row>
              </GridContainer>
            </BoundedContent>
          </article>
        )}
      </>
    </Redirect>
  );
};

export default GoogleWorkspacePdpA;
